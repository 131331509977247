import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SigninComponent} from './components/signin/signin.component';
import {AuthGuard} from './guards/auth.guard';
import {RoleGuard} from './guards/role.guard';

const routes: Routes = [
  {path: '', redirectTo: '/home', pathMatch: 'full'},
  {
    path: 'signin', component: SigninComponent
  },
  {
    path: 'home',
    canActivate: [AuthGuard, RoleGuard],
    data: {
      expectedRole: 'Admin'
    },
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

