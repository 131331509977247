import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  authenticate(email: string, password: string): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}user/authenticate`, {
      Email: email,
      Password: password
    })
      .pipe(res => {
        return res;
      });
  }
}
