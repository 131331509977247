import {Component, OnInit} from '@angular/core';
import {UserService} from '../../services/user.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {

  email: string;
  password: string;

  constructor(private userService: UserService, private router: Router) {
  }

  ngOnInit(): void {
  }

  public signIn(): void {
    if (this.email.length > 0 && this.password.length > 0) {
      this.userService.authenticate(this.email, this.password).subscribe(res => {
        console.log(res);
        localStorage.setItem('access_token', res.token);
        this.router.navigate(['home']);
      });
    }

  }
}
