<div class="main-wrapper" style="height: 100vh" fxLayout="row" fxLayoutAlign="center center">

  <mat-card class="box">
    <mat-card-header>
      <mat-card-title>Sign In</mat-card-title>
    </mat-card-header>

    <form class="example-form">
      <mat-card-content>
        <mat-form-field appearance="fill">
          <mat-label>Email</mat-label>
          <input matInput placeholder="Email" [(ngModel)]="email" name="email"/>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Password</mat-label>
          <input matInput placeholder="Password" [(ngModel)]="password" name="userpassword"/>
        </mat-form-field>
      </mat-card-content>
      <button mat-button (click)="signIn()" mat-flat-button color="primary">Sign-in</button>
    </form>
  </mat-card>

</div>
