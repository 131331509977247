import {Injectable} from '@angular/core';
import jwt_decode from 'jwt-decode';
import {Router} from '@angular/router';

export const TOKEN_NAME = 'access_token';

@Injectable()
export class AuthService {


  constructor(private router: Router) {
  }

  getToken(): string {
    return localStorage.getItem(TOKEN_NAME);
  }

  setToken(token: string): void {
    localStorage.setItem(TOKEN_NAME, token);
  }

  isAdmin(token: string, expectedRole: string): boolean {
    const decoded = jwt_decode(token);

    // @ts-ignore
    if (decoded.role === undefined) {
      return null;
    }

    // @ts-ignore
    return decoded.role === expectedRole;
  }

  getTokenExpirationDate(token: string): Date {
    const decoded = jwt_decode(token);

    // @ts-ignore
    if (decoded.exp === undefined) {
      return null;
    }

    const date = new Date(0);
    // @ts-ignore
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  isTokenExpired(token?: string): boolean {
    if (!token) {
      token = this.getToken();
    }

    if (!token) {
      return true;
    }

    const date = this.getTokenExpirationDate(token);
    if (date === undefined) {
      return false;
    }
    return !(date.valueOf() > new Date().valueOf());
  }

  public async logout(): Promise<void> {
    await localStorage.clear();
    this.router.navigate(['/login']);
  }
}
